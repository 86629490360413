import gql from 'graphql-tag'

import { ConsentFragment, ProfileFragment } from './fragments'

export const GET_USER_PROFILE = gql`
  query getMyProfile {
    getMyProfile {
      ...ProfileData
    }
  }
  ${ProfileFragment}
`

export const GET_PORTAL_BRANDING = gql`
  query getPortalBranding($input: IDOrSlugInput!) {
    getPortalBranding(input: $input) {
      accentColor
      brandColor
      brandImage
      brandMedia
      footerImage
      landingHeaderColor
      landingLeftLogo
      landingRightLogo
      favicon
      id
      logo
      logoSmall
      name
      onboardingAssets {
        en {
          text
          imageUrl
        }
        de {
          text
          imageUrl
        }
      }
      isDemoAccount
      redirectDomains
      randomizeProductsOrder
      languages
    }
  }
`

export const LIST_MY_CONSENTS = gql`
  query listMyConsents {
    listMyConsents {
      ...ConsentFragment
    }
  }
  ${ConsentFragment}
`

export const GET_EMAIL_AVAILABILITY = gql`
  query isEmailAvailable($input: String!) {
    isEmailAvailable(input: $input)
  }
`

export const GET_TERMS_PAGE = gql`
  query getTermsPage($input: TermsPageInput!) {
    getTermsPage(input: $input) {
      id
      html
    }
  }
`
