import { useContext } from 'react'

import { useQuery } from '@apollo/client'

import { BrandingContext } from '@etvas/etvaskit'

import { currentLocation, storage } from '@shared/funcs'

import { GET_PORTAL_BRANDING } from '../graphql/queries'

export const useGetBranding = () => {
  const { brandingService } = useContext(BrandingContext)
  const { slug } = currentLocation

  const { loading, data, error } = useQuery(GET_PORTAL_BRANDING, {
    variables: { input: { slug } },
    context: {
      unauthenticated: true
    },
    onCompleted: data => {
      storage.organizationId = data.getPortalBranding.id

      const cssVars = [
        'brandColor',
        'accentColor',
        'logoSmall',
        'logo',
        'brandImage'
      ]
      const vars = cssVars.reduce(
        (o, k) => ({ ...o, [k]: data?.getPortalBranding[k] }),
        {}
      )

      brandingService.updateCssVars(vars)
    }
  })

  return {
    slug,
    loading,
    error,
    organization: data?.getPortalBranding || null
  }
}
